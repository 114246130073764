<template>
  <div class="landing-page">
    <a-layout class="layout">
      <div>
        <Navbar />
        <HeroSections />
        <InformasiPembayaran />
        <AlurPelaksanaan />
        <BiayaKeuangan />
        <AjakanDaftar />
        <Footer />
      </div>
    </a-layout>
  </div>
</template>
<script>

import { mapState } from 'vuex' // Jika Anda menggunakan Vuex untuk manajemen state

const HeroSections = () => import('@/views/Ppdb/LandingPage/fragments/hero')
const InformasiPembayaran = () => import('@/views/Ppdb/LandingPage/fragments/informasiPembayaran')
const AlurPelaksanaan = () => import('@/views/Ppdb/LandingPage/fragments/alurPelaksanaan')
const BiayaKeuangan = () => import('@/views/Ppdb/LandingPage/fragments/biayaKeuangan')
const AjakanDaftar = () => import('@/views/Ppdb/LandingPage/fragments/persuationSection')
const Footer = () => import('@/views/Ppdb/LandingPage/fragments/footer')
const Navbar = () => import('@/views/Ppdb/LandingPage/fragments/navbar')
export default {
  components: {
    Navbar,
    HeroSections,
    InformasiPembayaran,
    AlurPelaksanaan,
    BiayaKeuangan,
    AjakanDaftar,
    Footer,
  },
  data() {
    return {
      visible: false,
      modalImage: 0,
    }
  },
  computed: {
    ...mapState(['currentRoute']), // Anda dapat menggunakan Vuex untuk mengelola route aktif
    institusi() {
      return this.$store.state.master.institusi
    },
  },
  methods: {
    scrollToSection(sectionId) {
      const element = document.getElementById(sectionId)
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' })
      }
    },
    onChange(a, b, c) {
      console.log(a, b, c)
    },
    showModal(idx) {
      this.visible = true
      this.modalImage = idx
    },
    handleOk(e) {
      console.log(e)
      this.visible = false
    },
  },
}
</script>
<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@800&family=Montserrat:wght@400;500;700&family=Poppins:ital,wght@0,500;0,700;1,400&family=Raleway:wght@500;600;800&display=swap');

/* Style untuk landing page */
.landing-page {
  font-family: Arial, sans-serif;
  text-align: right;
  /* width: 100vw; */
  overflow-x: hidden;
}

.layout {
  /* min-height: 100vh; */
  background-color: white;
}
</style>
